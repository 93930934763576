<template>
  <div>
    <component
      :is="imprComponent"
      :riskReduce.sync="riskReduce"
      :param="param"
      :height="height"
      @research="research"
    />
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'riskTab',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        ramTechniqueCd: '',
        searchRiskReduce: {
          flag: '',
          isSearch: false,
        },
        searchScenario: {
          flag: '',
          isSearch: false,
        },
        planUpdateBtnData: {
          title: '계획',
          flag: false,
          research: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      editable: true,
      tab: 'impr',
      imprComponent: () => import(`${'@/pages/ram/4m/risk/4mImpr.vue'}`),
      riskReduce: {
        imprs: [],
        riskbooks: [],
      }
    };
  },
  watch: {
    'param.searchRiskReduce.flag'() {
      this.getList();
    },
    'param.planUpdateBtnData.research'() {
      this.getList();
    },
  },
  computed: {
    // tabItems() {
    //   let imprComponent = null;
    //   let riskComponent = null;
    //   if (this.param.ramTechniqueCd === 'RT00000001') {
    //     // HAZOP
    //     // imprComponent = () => import(`${'@/pages/ram/hazop/risk/hazopImpr.vue'}`)
    //     // riskComponent = () => import(`${'@/pages/ram/hazop/risk/hazopRiskRegister.vue'}`)
    //   } else if (this.param.ramTechniqueCd === 'RT00000005') {
    //     // K-PSR
    //     // imprComponent = () => import(`${'@/pages/ram/kpsr/risk/kpsrImpr.vue'}`)
    //     // riskComponent = () => import(`${'@/pages/ram/kpsr/risk/kpsrRiskRegister.vue'}`)
    //   } else if (this.param.ramTechniqueCd === 'RT00000010') {
    //     // Check-list
    //     // imprComponent = () => import(`${'@/pages/ram/checklist/risk/checklistImpr.vue'}`)
    //     // riskComponent = () => import(`${'@/pages/ram/checklist/risk/checklistRiskRegister.vue'}`)
    //   } else if (this.param.ramTechniqueCd === 'RT00000015') {
    //     // JRA
    //     // imprComponent = () => import(`${'@/pages/ram/jsa/risk/jsaImpr.vue'}`)
    //     // riskComponent = () => import(`${'@/pages/ram/jsa/risk/jsaRiskRegister.vue'}`)
    //   } else if (this.param.ramTechniqueCd === 'RT00000020') {
    //     // KRAS
    //     // imprComponent = () => import(`${'@/pages/ram/kras/risk/krasImpr.vue'}`)
    //     // riskComponent = () => import(`${'@/pages/ram/kras/risk/krasRiskRegister.vue'}`)
    //   } else if (this.param.ramTechniqueCd === 'RT00000025') {
    //     // 4M
    //     imprComponent = () => import(`${'@/pages/ram/4m/risk/4mImpr.vue'}`)
    //     riskComponent = () => import(`${'@/pages/ram/4m/risk/4mRiskRegister.vue'}`)
    //   } else if (this.param.ramTechniqueCd === 'RT00000030') {
    //     // CHARM
    //     // imprComponent = () => import(`${'@/pages/ram/charm/risk/charmImpr.vue'}`)
    //     // riskComponent = () => import(`${'@/pages/ram/charm/risk/charmRiskRegister.vue'}`)
    //   }
    //   return [
    //     { name: 'impr', icon: 'construction', label: 'Punch List', component: imprComponent },
    //     { name: 'risk', icon: 'report_gmailerrorred', label: '위험등록부', component: riskComponent },
    //   ];
    // },
    listUrl() {
      let url = null;
      if (this.param.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        url = selectConfig.ram.hazop.riskReduce.list.url;
      } else if (this.param.ramTechniqueCd === 'RT00000005') {
        // K-PSR
        url = selectConfig.ram.kpsr.riskReduce.list.url;
      } else if (this.param.ramTechniqueCd === 'RT00000010') {
        // Check-list
        url = selectConfig.ram.checklist.riskReduce.list.url;
      } else if (this.param.ramTechniqueCd === 'RT00000015') {
        // JRA
        url = selectConfig.ram.jsa.riskReduce.list.url;
      } else if (this.param.ramTechniqueCd === 'RT00000020') {
        // KRAS
        url = selectConfig.ram.kras.riskReduce.list.url;
      } else if (this.param.ramTechniqueCd === 'RT00000025') {
        // 4M
        url = selectConfig.ram.fm.riskReduce.list.url;
      } else if (this.param.ramTechniqueCd === 'RT00000030') {
        // CHARM
        url = selectConfig.ram.charm.riskReduce.list.url;
      }
      return url;
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId
      }
      this.$http.request((_result) => {
        this.$_.extend(this.riskReduce, _result.data);
      },);
    },
    research() {
      this.$emit('emitStep', {
        name: 'research',
        param: {
          place: 'riskReduce',
        }
      })
    },
  }
};
</script>
