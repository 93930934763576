var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(_vm.imprComponent, {
        tag: "component",
        attrs: {
          riskReduce: _vm.riskReduce,
          param: _vm.param,
          height: _vm.height,
        },
        on: {
          "update:riskReduce": function ($event) {
            _vm.riskReduce = $event
          },
          "update:risk-reduce": function ($event) {
            _vm.riskReduce = $event
          },
          research: _vm.research,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }